import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import NavigationBar from '@/components/NavigationBar.vue';
import FooterPart from '@/components/FooterPart.vue';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      const _component_el_scrollbar = _resolveComponent("el-scrollbar");
      return _openBlock(), _createBlock(_component_el_scrollbar, {
        "max-height": "100vh"
      }, {
        default: _withCtx(() => [_createVNode(NavigationBar), _createVNode(_component_router_view, {
          class: "main-content"
        }), _createVNode(FooterPart)]),
        _: 1
      });
    };
  }
};