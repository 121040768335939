import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/editionComparison',
    name: 'editionComparison',
    component: () => import('../views/EditionComparisonView.vue')
  },
  {
    path: '/updateLog/:edition',
    name: 'updateLog',
    alias: '/updateLog',
    component: () => import('../views/UpdateLogView.vue'),
    props: true
  },
  {
    path: '/webApp',
    name: 'webApp',
    component: () => import('../views/WebAppView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
